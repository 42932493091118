html,
body {
  // background-image: linear-gradient(to right, black 0%, #434343 100%);
  background: #1f1f1f;
  color: white;
  * {
    color: white;
  }
  width: fit-content;
  height: fit-content;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}
